var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"container p-0 mb-4 dds"},[_c('h1',{staticClass:"h4 pl-3"},[_vm._v("Destaques da semana:")]),_c('carousel',{attrs:{"perPageCustom":[
        [425, 3],
        [768, 4],
        [1024, 6],
        [1440, 7],
      ],"navigationEnabled":true,"paginationEnabled":false}},_vm._l((_vm.filteredProductsList),function(product,index){return _c('slide',{key:index},[_c('div',{staticClass:"card",on:{"click":function($event){$event.preventDefault();return _vm.goToProduct(
              product.categoria.descricao,
              product.descricao,
              product.id
            )}}},[(product.imagem[0] && product.imagem[0].link)?_c('div',{staticClass:"card-img",style:(`background-image: url(${_vm.ajustarImg(
              product.imagem[0].link
            )})`)}):_c('div',{staticClass:"card-img",style:(`background-image: url(${product.imageThumbnail})`)}),_c('div',{staticClass:"card-content"},[_c('p',{staticClass:"text-truncate"},[_vm._v(_vm._s(product.descricao))]),_c('h5',[_vm._v(" "+_vm._s(_vm._f("currency")(((product.preco / 100) * 95),"R$ ", 2, { thousandsSeparator: ".", decimalSeparator: ",", }))+" "),_c('span',{staticStyle:{"font-size":"8px"}},[_vm._v(" no Pix ")])]),_c('small',[_vm._v("ou 3 x de "+_vm._s(_vm._f("currency")((product.preco / 3),"R$ ", 2, { thousandsSeparator: ".", decimalSeparator: ",", })))])])])])}),1)],1),_c('section',{staticClass:"container p-0 mb-4 cpc"},[_c('h1',{staticClass:"h4 pl-3"},[_vm._v("Compre por Categoria:")]),_c('div',{staticClass:"container"},[_c('carousel',{attrs:{"perPageCustom":[
          [320, 3],
          [375, 4],
          [768, 8],
          [1024, 12],
        ],"navigationEnabled":true,"paginationEnabled":false}},[_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'ELÉTRICA' },
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/eletrica.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Elétrica")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'ILUMINAÇÃO' },
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/iluminacao.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Iluminação")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'MOTOR' },
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/motor.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Motor")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'PNEUS' }, //PNEUS
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/pneus-e-camaras.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Pneus e Câmaras")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'RETROVISORES' },
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/retrovisor.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Retrovisor")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'SUSPENSÃO' },
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/suspensao.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Suspensão")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'TRANSMISSÃO' },
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/transmissao.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Transmissão")])])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }