var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"container p-0 mb-4 dds"},[_c('h1',{staticClass:"h4 pl-3"},[_vm._v("Destaques da semana:")]),_c('carousel',{attrs:{"perPageCustom":[
        [425, 3],
        [768, 4],
        [1024, 6],
        [1440, 7],
      ],"navigationEnabled":true,"paginationEnabled":false}},_vm._l((_vm.productsList),function(product,index){return _c('slide',{key:index,style:(product.categoria.descricao == 'PNEUS'
            ? 'display: none;'
            : '' || product.categoria.descricao == 'PNEU'
            ? 'display: none;'
            : '' || product.categoria.descricao == 'OLEO'
            ? 'display: none;'
            : '' || product.categoria.descricao == 'OLEOS'
            ? 'display: none;'
            : '' || product.categoria.descricao == 'ÓLEOS'
            ? 'display: none;'
            : '')},[_c('div',{staticClass:"card",on:{"click":function($event){$event.preventDefault();return _vm.goToProduct(
              product.categoria.descricao,
              product.descricao,
              product.id
            )}}},[(product.imagem[0] && product.imagem[0].link)?_c('div',{staticClass:"card-img",style:(`background-image: url(${_vm.ajustarImg(
              product.imagem[0].link
            )})`)}):_c('div',{staticClass:"card-img",style:(`background-image: url(${product.imageThumbnail})`)}),_c('div',{staticClass:"card-content"},[_c('p',{staticClass:"text-truncate"},[_vm._v(_vm._s(product.descricao))]),_c('h5',[_vm._v(" "+_vm._s(_vm._f("currency")(((product.preco / 100) * 95),"R$ ", 2, { thousandsSeparator: ".", decimalSeparator: ",", }))+" "),_c('span',{staticStyle:{"font-size":"8px"}},[_vm._v(" no Pix ")])]),_c('small',[_vm._v("ou 3 x de "+_vm._s(_vm._f("currency")((product.preco / 3),"R$ ", 2, { thousandsSeparator: ".", decimalSeparator: ",", })))])])])])}),1)],1),_c('section',{staticClass:"container p-0 mb-4 cpc"},[_c('h1',{staticClass:"h4 pl-3"},[_vm._v("Compre por Categoria:")]),_c('div',{staticClass:"container"},[_c('carousel',{attrs:{"perPageCustom":[
          [320, 3],
          [375, 4],
          [768, 8],
          [1024, 12],
        ],"navigationEnabled":true,"paginationEnabled":false}},[_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'Acessórios' },
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/acessorios.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Acessórios")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'BAG' }, //BAG
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/bag.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Bag")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'KITS' }, //KITS
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/kit.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Kit")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'VESTUÁRIO' }, //VESTUÁRIO
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/vestuario.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Vestuário")])]),_c('slide',{staticClass:"slide"},[_c('div',{staticClass:"category-img",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'product-list',
                params: { categoria: 'CALÇADOS' }, //CALÇADOS
              })}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/calcados.png")}})]),_c('p',{staticClass:"mb-0"},[_vm._v("Calçados")])])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }