import { http } from "@/config/http";

export const serialize = function (obj) {
	const searchParams = new URLSearchParams();
	for (let key in obj) {
		if (typeof obj[key] === "object") {
			for (let iKey in obj[key]) {
				if (Array.isArray(obj[key][iKey])) {
					obj[key][iKey].forEach((arrValue, index) => {
						if (arrValue != '')
							searchParams.append(`filter[${iKey}][${index}]`, arrValue)
					})
				} else {
					if (obj[key][iKey] != '') searchParams.append(`filter[${iKey}]`, obj[key][iKey]);
				}
			}
		} else searchParams.append(`${key}`, obj[key]);
	}
	return `?${decodeURIComponent(searchParams.toString())}`;
}

export const productsList = (params) => http.get(`domain/products${serialize(params)}`);
export const product = (params) => {
	let url = "domain/products";

	if (params.products && Array.isArray(params.products)) {
		url += "?" + params.products.map((product) => `ids=${product.id}`).join("&");
	} else {
		url += serialize(params);
	}

	if (params.limit !== undefined && params.offset !== undefined) url += `&limit=${params.limit}&offset=${params.offset}`;

	return http.get(url);
};
export const getPurchaseList = (params) => {
	if (params.user_id) return http.get(`domain/orders/${params.user_id}?limit=35`);
}
export const user = (params) => http.get(`https://1axcgmvzi0.execute-api.us-east-1.amazonaws.com/prd/driver/${params.user_id}`);
export const zipCode = (payload) => http.get(`https://viacep.com.br/ws/${payload.zipCode}/json`);
export const generateToken = (payload) => {
	if (location.hostname == 'dev.lojadoentregadorifood.com.br' || location.hostname == 'localhost') {
		return http.post(`https://api.pagar.me/core/v5/tokens?appId=pk_test_dvmj9BDuJszY8awq`, payload);
	}
	else {
		return http.post(`https://api.pagar.me/core/v5/tokens?appId=pk_pLqN7mlu8Aiv9K2o`, payload);
	}
}
//export const sendOrder = (payload) => http.post(`order`, payload);
//export const sendOrderPix = (payload) => http.post(`order/marlim/pix`, payload);
export const sendOrder = (payload) => http.post(`domain/order/pagseguro/card`, payload);
export const sendOrderPix = (payload) => http.post(`domain/order/pagseguro/pix`, payload); //order/marlim/pix
export const freight = (freight) => http.post(`domain/freight`, freight);
export const searchMooveApi = (payload) => http.get(`domain/freight/moove?zip=${payload.zip}&weight=${payload.weight*1000}&stock=${payload.stock}`);
export const cupom = (payload) => http.get(`domain/coupon/${payload.cupom}/${payload.user}?limit=1`);
export const banner = (payload) => http.get(`domain/banner?status=ativo&region=${payload.region ? payload.region.replaceAll(" ", "_") : "Todos"}&user=${payload.user_id}`);
export const sendSac = (payload) => http.post(`domain/sac`, payload);
export const pickoff = (payload) => http.post(`domain/pickoff`, payload);
export const blacklist = (payload) => http.get(`domain/ban/${payload.user_id}`);
export const jadlogFrete = () => http.post(`https://www.jadlog.com.br/embarcador/api/frete/valor`);
//export const getSac = (params) => { if (params) { return http.get(`sac${params}`); } else { return http.get(`sac?offset=0`); } };
export const getSacItem = (id) => http.get(`adm/store/sac?bling=${id}`);

export const getCoupons = (params) => http.get(`domain/my-coupons/${params}`);